<template>
    <comp-form
        title="分类"
        ref="comp_form"
        submit-api="/gateway/api/sycompany/pc/label/createLabel"
        edit-api="/gateway/api/sycompany/pc/label/updateLabel"
        detail-api="/gateway/api/sycompany/pc/label/getLabelById"
        id-key="labelById"
        :submit-before="onSubmitBefore"
        :data-before="onDataBefore"
        @on-submit="onSubmit"
    >
        <template v-slot:form-box="data">
            <ElemFormItem column title="分类名称" name="labelName" required>
                <Input style="width:400px" maxlength="100" v-model="data.form.labelName" placeholder="请填写分类名称"></Input>
            </ElemFormItem>
            <ElemFormItem column title="所属上级分类（为空时默认顶级分类）">
                <Cascader change-on-select :data="classify" v-model="data.form.parentId" style="width:400px" transfer />
            </ElemFormItem>
            <ElemFormItem column title="排序">
                <Input style="width:400px" type="number" v-model="data.form.sort" placeholder="请填写排序数值（从小到大）"></Input>
            </ElemFormItem>
            <ElemFormItem column title="分类图标">
                <ElemUpload :value="data.form.icon" :concise="true" @on-change="data.form.icon = $event.value"></ElemUpload>
            </ElemFormItem>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "../../jointly/components/CompForm.vue"
import ElemFormItem from "../../jointly/components/elem-form-item.vue"
import ElemUpload from "../../jointly/components/elem-upload.vue"

export default {
    components: { CompForm, ElemFormItem, ElemUpload },

    data() {
        return {}
    },

    props: {
        // 分类列表
        classify: {
            type: Array,
            default: () => [],
        },
    },

    mounted() {},

    methods: {
        /**
         * 打开表单窗口
         */
        open(id) {
            this.$refs.comp_form.open(id)
        },

        /**
         * 提交完成事件
         */
        onSubmit() {
            this.$emit("on-submit")
        },

        onDataBefore(data) {
            if (data.parentId && data.parentId !== "0") {
                // 层级遍历查找父级
                function findParent(list, id, ids) {
                    for (let i = 0; i < list.length; i++) {
                        if (list[i].id === id) {
                            ids.push(list[i].id)
                            return ids
                        } else if (list[i].children && list[i].children.length > 0) {
                            ids.push(list[i].id)
                            return findParent(list[i].children, id, ids)
                        }
                    }
                }

                const ids = findParent(this.classify, data.parentId, [])

                data.parentId = ids
            }

            return data
        },

        /**
         * 提交数据前置事件
         */
        onSubmitBefore(data) {
            data.oemCode = parent.vue.oemInfo.oemCode
            data.labelType = "4"

            if (!data.sort) {
                data.sort = 0
            }

            if (data.parentId) {
                data.parentId = data.parentId[data.parentId.length - 1]
            } else {
                data.parentId = "0"
            }

            return data
        },
    },
}
</script>
